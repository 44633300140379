
export const maxDeposit = 7500;
export const minimumPrice = 39.00;

// Define the default price multiplier
const LEGACY_PRICE_MULTIPLIER = 3.9;
const NEW_PRICE_MULTIPLIER = 4.3;

// Check if 'heysafeNewPrice' is defined in the window object
// and set the price multiplier accordingly
const priceMultiplier = window?.heysafeNewPrice ? NEW_PRICE_MULTIPLIER : LEGACY_PRICE_MULTIPLIER;

// Export the priceRate constant
export const priceRate = priceMultiplier;