<template>
    <input class="w-64 bg-gray-50 rounded-lg text-3xl font-semibold text-center" type="text" v-model="displayValue"
           @blur="isInputActive = false" @focus="isInputActive = true"/>
</template>

<script>
export default {
  name: 'currency-input',
  props: ['modelValue'],
  data () {
    return {
      isInputActive: false
    }
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          // cursor is inside the field, unformat display value
          return this.modelValue
        } else {
          // User is not modifiying now. Format display for user
          return this.modelValue.toFixed(0) + ' €'
        }
      },
      set: function (modifiedValue) {
        // recalculate value after ignore '$' and '.' in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d]/g, ''))
        // Ensure that is a number
        if (isNaN(newValue)) {
          newValue = 0
        }
        this.$emit('update:modelValue', newValue);
      }
    }
  }
}
</script>
